import React, { Component } from 'react';
import classNames from "classnames";
import similar from 'string-similarity';
import { container, paragraph, formLabel } from '../styles/challenge.module.css';

class Challenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: false,
      invalid: false,
      value: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const state = { value: event.target.value }
    if ( this.state.invalid && event.target.value.length > 0 ) { state.invalid = false }
    this.setState( state );
  }

  handleSubmit(event) {
    const { answer } = this.props;
    const similarity = similar.compareTwoStrings(this.state.value, answer);
    if ( similarity > 0.85 ) {
      this.setState({ authorized: true });
    } else {
      this.setState({
        invalid: true,
        value: ''
      });
    }
    event.preventDefault();
  }

  render() {
    const { authorized, invalid, value } = this.state;
    const { question, children } = this.props;
    const inputClass = classNames("form-control", { "is-invalid": invalid });
    if ( authorized ) {
      return (
        <div>{ children }</div>
      )
    } else {
      return (
        <div className={container}>
          <div className="align-middle">
            <p className={paragraph}>The following content contains explicit material not suitable for all ages or people.</p>
            <p className={paragraph}>To view this content you must be able to complete the following rap lyrics.</p>
            <form onSubmit={this.handleSubmit}>
              <div class="form-floating mb-3">
                <input type="text" className={inputClass} id="floatingInput" value={value} onChange={this.handleChange} />
                <label className={formLabel} for="floatingInput">{ question }</label>
              </div>
            </form>
          </div>
        </div>
      )
    }
  }
}

export default Challenge;