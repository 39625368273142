/* eslint-disable react/jsx-pascal-case */
import * as React from 'react'
import { Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import { Col } from 'react-bootstrap'
import { CaretLeftFill } from 'react-bootstrap-icons'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { metadata, backTo } from '../styles/pages.module.css'
import Challenge from '../components/challenge'
import { Player, BigPlayButton } from 'video-react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const shortcodes = { Challenge };

const ProjectPost = ( data ) => {
  const { frontmatter, body, excerpt } = data.pageContext.node
  const metaImage = frontmatter.hero_image
    ? frontmatter.hero_image.childImageSharp.resize
    : null
  return (
    <Layout pageTitle={frontmatter.title} section="projects">
      <SEO
          title={`project • ${frontmatter.title}`}
          description={frontmatter.description || excerpt}
          image={metaImage}
          pathname={data.path}
        />
      <Col md={9} lg={8} xl={7}>
        {frontmatter.project_type === 'video' &&
          <Challenge question={frontmatter.video_question} answer={frontmatter.video_answer}>
            <Player
              playsInline
              autoPlay
              src={frontmatter.video_path}>
              <BigPlayButton position="center" />
            </Player>
          </Challenge>
        }
        {frontmatter.project_type !== 'video' &&
          <GatsbyImage className="rounded" layout="fullWidth" image={getImage(frontmatter.hero_image)} alt={frontmatter.hero_image_alt} />
        }
        <h1 className='mb-0 pt-2'>{frontmatter.title}</h1>
        <span className={metadata}>{frontmatter.date}</span>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {body}
          </MDXRenderer>
        </MDXProvider>
        <Link to="/projects" className={backTo}>
          <CaretLeftFill />All Projects
        </Link>
      </Col>
    </Layout>
  )
}

export default ProjectPost